var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageTitle',{attrs:{"hide_btns":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Все уведомления ")]},proxy:true},{key:"inner",fn:function(){return [_c('DateTime',{class:_vm.$style.dateTime})]},proxy:true}])}),_c('v-card',{class:_vm.$style.card},[_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary darken-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",class:_vm.$style.buttonIsReadState,attrs:{"icon":""},on:{"click":function($event){return _vm.readed_all_notifs()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-email-open-outline ")])],1)]}}])},[_c('span',[_vm._v("Отметить как прочитанное")])]),_c('v-switch',{staticClass:"ml-7",attrs:{"color":"accent darken-3","label":"показать только непрочитанные"},model:{value:(_vm.unreadFilterModel),callback:function ($$v) {_vm.unreadFilterModel=$$v},expression:"unreadFilterModel"}}),_c('v-btn',{class:[_vm.$style.btnOuter , 'mb-0 ml-auto'],attrs:{"text":""},on:{"click":_vm.show_setting_notifs_modal}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tune")]),_c('span',[_vm._v("Настройка получения уведомлений")])],1)],1),_c('Table',{attrs:{"headers":_vm.cols,"items":_vm.notifs,"options":_vm.table_options,"custom-sort":_vm.prevent_sort,"loading":_vm.notifs_loading,"single-select":false,"show-select":"","hide-default-footer":"","no-data-text":"Уведомления отсутствуют","not_resizeble":true},on:{"update:options":function($event){_vm.table_options=$event}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"value":isSelected,"readonly":item.is_read,"disabled":item.is_read},on:{"input":function($event){return select($event)}}})]}},{key:"item.section",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2 headline font-weight-regular"},[_vm._v(" "+_vm._s(item.section.name)+" ")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2 headline font-weight-regular"},[_c('div',{class:[_vm.$style.text, _vm.$style.class2, !item.is_read ? _vm.$style.text_not_read : ''],on:{"click":function($event){return _vm.go_to_notification_detail(item)}}},[_vm._v(_vm._s(item.title))])])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2 headline font-weight-regular",class:_vm.$style.table_text},[_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")])]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2 headline font-weight-regular"},[_vm._v(" "+_vm._s(item.time)+" ")])]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":function($event){return _vm.upd_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_pagination('limit', $event)}}})]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('SimpleDialog',{attrs:{"center_title":"","center_text":"","max-width":"528","bts_align":"center"},on:{"close":function($event){_vm.setting_notifs_model = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{class:_vm.$style.title},[_vm._v("Настройка получения уведомлений по разделам ЛКП")])]},proxy:true},{key:"text",fn:function(){return [_c('div',{staticClass:"pb-4"},[_c('Checkbox',{attrs:{"input-value":_vm.check_all_model,"indeterminate":_vm.is_indeterminate,"label":"Выбрать все","hide-details":""},on:{"change":_vm.select_all}}),_vm._l((_vm.user_notifs),function(item,idx){return _c('div',{key:idx},[_c('Checkbox',{class:_vm.$style.checkbox,attrs:{"input-value":item.is_allowed,"value":item.is_allowed,"label":item.name,"hide-details":""},on:{"change":function($event){return _vm.upd_checkbox_value(idx)}}})],1)})],2)]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"depressed":"","color":"accent darken-4"},on:{"click":_vm.save_settings}},[_vm._v(" Сохранить ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.setting_notifs_model = false}}},[_vm._v(" Отменить ")])]},proxy:true}]),model:{value:(_vm.setting_notifs_model),callback:function ($$v) {_vm.setting_notifs_model=$$v},expression:"setting_notifs_model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }